import { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Product from '../components/product'
import Form from '../components/form'

/** @jsx jsx */
import { jsx, Container } from 'theme-ui'

import { MdMessage } from "react-icons/md"
// import Button from '../components/button'

const ReferencementPage = () => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle || "notre site"
    const showPrice = true
    const filteredProducts = products().filter(product => product.level !== '1').map(product => ({
        id: `${product.id}`, 
        level: `${product.level}`, 
        name: product.levelName,
        price: product.price,
    }))

    const [cart, setCart] = useState({})

    const toggleCartItem = id => setCart( prevCart => ({
        ...prevCart,
        [id]: !prevCart[id],
    }) )

    return (
        <Layout>
            <SEO title={`Référencement - Obtenir ou améliorer son listing sur ${siteName}`} />

            <Container>
                <div sx={{
                    py: 4,
                    mb: 5,
                }}>    
                    <h1 sx={{
                        fontFamily: 'heading', fontWeight: 'heading',
                        fontSize: [5, 5, 8],
                        lineHeight: 1,
                        mb: 0,
                    }}>
                        Votre <span sx={gradient}>Référencement</span>
                    </h1>
                    <h2 sx={{
                        fontFamily: 'body', fontWeight: 'heading', fontSize: 5,
                    }}>
                        Obtenir ou améliorer son listing sur <span sx={{color: 'grey'}}>{siteName}</span>
                    </h2>
                    <p sx={{fontSize: 3,}}>Nous sommes une plateforme de référencement local premium et spécialisée. Accédez à une exposion immédiate et qualitative aux audiences en recherche de vos services. Référencez votre entreprise avec une fiche de base, ou présentez vos services avec une page premium complète. Boostez votre listing pour apparaître en premières positions. <b>Offrez à votre entreprise la visibilité ciblée qu'elle mérite.</b></p>

                    {/* <Button className="button" data-button="contact-anchor" link={"#contact"}><b>DEMANDER UNE OFFRE</b></Button> */}
                </div>

                {products(siteName).map(product => (
                    <Product 
                        key={`${product.levelName}-${product.level}`}
                        showPrice={showPrice}
                        id={product.id}
                        level={product.level}
                        levelName={product.levelName}
                        title={product.title}
                        price={product.price}
                        included={product.included}
                        benefits={product.benefits}
                        // defaultOpen={product.level !== '1' ? null : true}
                        defaultOpen={true}
                        cart={cart}
                        toggleCartItem={toggleCartItem}
                        video_uid={product.video_uid}
                    >
                        {product.children}
                    </Product>
                ))}

                <div id="contact"
                    sx={{
                        mt: 6,
                        bg: 'background',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 0,
                    }}
                >
                    <Form 
                        products={filteredProducts}
                        cart={cart}
                        toggleCartItem={toggleCartItem}
                    />
                </div>

                <a href="#contact" className="button" data-button="contact-bubble">
                    <div sx={{
                        position: 'fixed', bottom: '10px', left: '10px',
                        p: 3, borderRadius: '100px', lineHeight: '1',
                        bg: 'primary',
                        display: 'flex', justifyContent: "center", alignItems: "center",
                        cursor: 'pointer',
                    }}>
                        <MdMessage sx={{fontSize: 5, color: "white"}}/>
                    </div>
                </a>
            </Container>
      </Layout>
    )
}

export default ReferencementPage

const pageQuery = graphql`
  query referencementQuery {
    site {
      siteMetadata {
        siteTitle
        siteDesc
        siteTagline
      }
    }
  }
`

const gradient = {
    background: theme => `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
}

const products = (siteName) => ([
    {
        key: '1',
        id: 'base',
        level: '1',
        levelName: 'BASE',
        title: ['Apparaître', `sur le listing ${siteName}`],
        price: '49.- CHF HT (Paiement Unique)',
        included: [
            <ul key='included-base'>
                <li>Votre entreprise en tant que "vérifiée" dans le listing</li>
                <li>Coordonnées, liens, logo, pitch, réseaux sociaux</li>
                <li>Une mise à jour gratuite par an (puis 10 chf)</li>
            </ul>
        ],
        benefits: [
            <ul key='included-benefits'>
                <li>Apparaissez dans les résultats de recherche</li>
                <li>Passez ensuite en premium à un tarif préférentiel</li>
                <li>Bonnes pratiques : contenus validés par nos expert.e.s</li>
            </ul>
        ],
        children: [
            <div key='base-listing'>
                <p>Vous souhaitez apparaître sur le listing de {siteName} pour être vu et trouvé dans les recherches ? C'est très simple, <b>il vous suffit remplir le formulaire ci-dessous</b> en nous fournissant les informations nécessaires.</p>
            </div>
        ],
    },
    {
        key: '2',
        id: 'premium',
        level: '2',
        levelName: 'PREMIUM',
        title: ['Présentez-vous', 'avec une page Premium claire et moderne'],
        price: '290.- CHF HT / an. Ou 90.- CHF HT par trimestre.',
        video_uid: "ggjZgCiAX3g",
        included: [
            <ul key='included-premium'>
                <li>Une page dédiée, qui présente vos activités</li>
                <li>Votre listing est amélioré et plus visuel</li>
                <li>Mises à jour gratuites pendant l'année</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-premium'>
                <li>Différenciez vous des autres entreprises</li>
                <li>Présentez-vous sous votre meilleur jour</li>
                <li>Bonnes pratiques : contenus validés par nos expert.e.s</li>
            </ul>
        ],
        children: [
            <p key='premium'>Sur {siteName} nous créons sur ce site votre fiche dédiée comportant toutes les informations claires et modules nécessaires pour présenter votre activité. Notre équipe s'occupe de tout, et vous guide pour présenter efficacement votre activité.</p>
        ],
    },
    {
        key: '3',
        level: '3',
        id: 'listing-boost',
        levelName: 'LISTING BOOST',
        title: ['Boostez', 'votre visibilité dans nos listings'],
        price: 'À partir de 90.- CHF HT pour 30 jours de Boost',
        video_uid: "0bL83yCdONQ",
        included: [
            <ul key='included-boost'>
                <li>Mise en avant en haut de première page</li>
                <li>Mise en avant en haut des listings cantonaux</li>
                <li>Mise en avant en haut des listings filtrés</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-boost'>
                <li>Maximisez votre visibilité sans attendre</li>
                <li>Soyez placé immédiatement en haut des résultats</li>
                <li>Montrez votre professionnalisme et dynamisme</li>
            </ul>
        ],
        children: [
            <p key='boost'>Vous souhaitez que votre profil apparaisse dans les premiers résultats sur {siteName} ? Nous vous permettons de mettre en avant votre profil dans les listings pour vous assurer un maximum de visibilité lorsque vous en avez besoin.</p>
        ],
    },    
    {
        key: '4',
        level: '4',
        id: 'media-pro',
        levelName: 'MEDIA PRO',
        title: ['Différenciez-vous', 'avec de beaux contenus multimédia'],
        price: 'À partir de 750.- CHF HT',
        video_uid: "4p-tPxZf1wY",
        included: [
            <ul key='included-mediapro'>
                <li>Vidéo de présentation de votre activité</li>
                <li>Photos de présentation (activité, équipe)</li>
                <li>Mise à disposition pour site web, réseaux sociaux, etc</li>
            </ul>
        ],
        benefits: [
            <ul key='benefits-mediapro'>
                <li>Vidéo professionnelle de qualité</li>
                <li>Humanisez votre présence et convainquez</li>
                <li>Différenciez votre fiche par un beau contenu</li>
            </ul>
        ],
        children: [
            <p key='media-pro'>Pour améliorer votre page dédiée sur {siteName}, au delà des modules de contenus que notre équipe met en place (voir ci-dessus: ADD-ON 1 - Premium), nous proposons de produire un contenu de niveau professionnel pour présenter votre entreprise en photo, vidéo, et éventuellement caméra 360°. Ces contenus sont aussi utilisables sur vos propres supports et vous appartiennent !</p>
        ],
    },
    // {
    //     key: '5',
    //     level: '5',
    //     id: 'storytelling-boost',
    //     levelName: 'STORYTELLING BOOST',
    //     title: ['Humanisez', 'avec votre reportage dédié'],
    //     price: 'À partir de 590.- CHF / reportage',
    //     included: [
    //         <ul key='included-story'>
    //             <li>Mise en avant sur ce site</li>
    //             <li>Publication sur vos réseaux sociaux</li>
    //             <li>Publication du reportage sur votre page</li>
    //         </ul>
    //     ],
    //     benefits: [
    //         <ul key='benefits-story'>
    //             <li>Faites-vous connaitre avec un contenu attirant</li>
    //             <li>Développez encore plus votre visibilité</li>
    //             <li>Démarquez-vous avec un reportage immersif</li>
    //         </ul>
    //     ],
    //     children: [
    //         <p key="storytelling">Afin d'avoir un maximum d'impact auprès des visiteurs de {siteName}, nous vous permettons de bénéficier d'un reportage publi-rédactionnel (en texte images et vidéo) afin de présenter votre activité sous un angle plus immersif (type reportage / documentaire). Notre équipe passe une demi journée avec vous et réalise un reportage immersif dans votre entreprise. Ce format et sa publication cette plateforme et vos supports (site, réseaux...) vous permet d'assurer une visibilité professionnelle et complète, tout en humanisant votre présence et en vous différenciant de vos compétiteurs.</p>
    //     ],
    // },
])